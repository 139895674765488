<template>
  <v-container
    id="my-profile"
    fluid
    tag="section"
  >
    <v-overlay :value="isOverlayVisible">
      <v-progress-circular
        :size="70"
        :width="7"
        color="amber"
        indeterminate
        class="spinner"
      />
    </v-overlay>
    <v-row justify="center">
      <v-col
        cols="12"
        md="12"
      >
        <div class="linkWrapper">
          <div
            class="link"
            @click="$router.push('/pages/transactions')"
          >
            <v-icon v-if="value">
              mdi-view-quilt
            </v-icon>
            View past transactions
          </div>
        </div>
      </v-col>
      <v-col
        cols="12"
        md="12"
      >
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Select Plan
            </div>
          </template>

          <v-form>
            <v-container class="py-0 pt-4 mt-4">
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-select
                    v-model="selectedPlanType"
                    :items="planTypes"
                    label="Price based on sending"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                >
                  <div class="pricesWrapper">
                    <div
                      v-for="(plan, planIndex) in allPlans[selectedPlanType]"
                      :key="planIndex"
                      class="priceCardOuterWrapper"
                    >
                      <div class="priceCardInnerWrapper">
                        <p class="priceHeading">
                          {{ plan.primaryHeading }}
                        </p>
                        <p class="priceSubHeading">
                          {{ plan.secondaryHeading }}
                        </p>
                        <p class="priceLabel">
                          {{ plan.priceLabel }}
                        </p>
                        <button
                          type="button"
                          class="priceSelectBtn"
                          @click="selectPlan(plan, selectedPlanType)"
                        >
                          Select
                        </button>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
    <stripe-payment-dialog
      :plan="selectedPackage"
      :selected-plan-type="selectedPlanType"
      :is-visible="isPaymentDialogVisible"
      @hide-payment-dialog="hidePaymentDialog"
      @on-payment-success="onPaymentSuccess"
    />
  </v-container>
</template>

<script>
  // import axios from 'axios'
  import { plans } from '../../../static-data/plans'

  export default {

    components: {
      StripePaymentDialog: () => import('../components/StripePaymentDialog'),
    },

    data: () => ({
      apiEndpoint: process.env.VUE_APP_API_BASE_URL,
      apiCode: process.env.VUE_APP_API_CODE,
      isOverlayVisible: false,
      username: '',
      twilioSid: '',
      twilioAuthToken: '',
      allPlans: {},
      isPaymentDialogVisible: false,
      selectedPackage: null,
      user: null,
      planTypes: ['SMS', 'MMS'],
      selectedPlanType: 'SMS',
    }),

    async created () {
      this.allPlans = plans
      this.username = localStorage.getItem('username')
    },

    methods: {
      hidePaymentDialog () {
        this.isPaymentDialogVisible = false
      },

      selectPlan (plan) {
        this.selectedPackage = plan
        this.isPaymentDialogVisible = true
      },

      onPaymentSuccess () {
        // Need to refresh the page in order to update all the components with the new balance
        window.location = `${window.location.origin}/pages/dashboard`
      },
    },
  }

</script>
<style lang="scss" scoped>

.pricesWrapper {
  background-color: rgb(245, 245, 245);
  margin-top: 10px;
  padding-top: 40px;
  flex-wrap: wrap;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.priceCardOuterWrapper {
  width: 300px;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.priceCardInnerWrapper {
  width: 250px;
  height: 300px;
  border-radius: 6px;
  background-color: rgb(255, 255, 255);
  cursor: pointer;
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.13);
  transition: 0.4s;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid white;
  flex-direction: column;
}
.priceCardInnerWrapper:hover {
  border: 2px solid white;
  background-color: rgb(255, 184, 52);
}
.priceHeading {
  font-size: 1.4rem;
  font-weight: 600;
}
.priceSubHeading {
  margin-top: -15px;
  font-size: 0.9rem;
  font-weight: 400;
}
.priceLabel {
  font-size: 2.3rem;
  font-weight: 600;
}
.priceSelectBtn {
  margin-top: 30px;
  border: 1px solid rgb(255, 184, 52);
  padding: 8px 58px;
}
.priceCardInnerWrapper:hover .priceSelectBtn {
  margin-top: 30px;
  border: 1px solid rgb(255, 255, 255);
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.13);
  background-color: white;
  transition: 0.4s;
  padding: 8px 58px;
}
.priceCardInnerWrapper:hover .priceSelectBtn:hover {
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0);
}

.linkWrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  .link {
    padding: 7px 35px;
    background-color: rgba(255, 187, 62, 0.931);
    cursor: pointer;
    border-radius: 18px;
    color: white;
    box-shadow: 5px 5px 15px rgba(0,0,0,0.12);
  }
}
</style>
