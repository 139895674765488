export const plans = {
  SMS: [{
    primaryHeading: '1000 SMS',
    secondaryHeading: 'Approx 0.02 per SMS',
    priceLabel: '$20',
    price: 20,
    planId: 2,
    type: 'onetime',
    rateTierId: 2,
  }, {
    primaryHeading: '3,333 SMS',
    secondaryHeading: 'Approx 0.015 per SMS',
    priceLabel: '$50',
    price: 50,
    planId: 3,
    type: 'onetime',
    rateTierId: 3,
  }, {
    primaryHeading: '10,000 SMS',
    secondaryHeading: 'Approx 0.01 per SMS',
    priceLabel: '$100',
    price: 100,
    planId: 4,
    type: 'onetime',
    rateTierId: 4,
  }, {
    primaryHeading: '20,000 SMS',
    secondaryHeading: 'Approx 0.01 per SMS',
    priceLabel: '$200',
    price: 200,
    planId: 5,
    type: 'onetime',
    rateTierId: 4,
  }, {
    primaryHeading: '50,000 SMS',
    secondaryHeading: 'Approx 0.01 per SMS',
    priceLabel: '$500',
    price: 500,
    planId: 6,
    type: 'onetime',
    rateTierId: 4,
  }, {
    primaryHeading: '125,000 SMS',
    secondaryHeading: 'Approx 0.008 per SMS',
    priceLabel: '$1000',
    price: 1000,
    planId: 7,
    type: 'onetime',
    rateTierId: 5,
  }, {
    primaryHeading: '187,500 SMS',
    secondaryHeading: 'Approx 0.008 per SMS',
    priceLabel: '$1500',
    price: 1500,
    planId: 8,
    type: 'onetime',
    rateTierId: 5,
  }, {
    primaryHeading: '250,000 SMS',
    secondaryHeading: 'Approx 0.008 per SMS',
    priceLabel: '$2000',
    price: 2000,
    planId: 9,
    type: 'onetime',
    rateTierId: 5,
  }, {
    primaryHeading: '375,000 SMS',
    secondaryHeading: 'Approx 0.008 per SMS',
    priceLabel: '$3000',
    price: 3000,
    planId: 10,
    type: 'onetime',
    rateTierId: 5,
  }, {
    primaryHeading: '687,500 SMS',
    secondaryHeading: 'Approx 0.008 per SMS',
    priceLabel: '$5500',
    price: 5500,
    planId: 11,
    type: 'onetime',
    rateTierId: 5,
  }],
  MMS: [{
    primaryHeading: '701 MMS',
    secondaryHeading: 'Approx 0.0285 per MMS',
    priceLabel: '$20',
    price: 20,
    planId: 2,
    type: 'onetime',
    rateTierId: 2,
  }, {
    primaryHeading: '1,754 MMS',
    secondaryHeading: 'Approx 0.0285 per MMS',
    priceLabel: '$50',
    price: 50,
    planId: 3,
    type: 'onetime',
    rateTierId: 3,
  }, {
    primaryHeading: '3,508 MMS',
    secondaryHeading: 'Approx 0.0285 per MMS',
    priceLabel: '$100',
    price: 100,
    planId: 4,
    type: 'onetime',
    rateTierId: 4,
  }, {
    primaryHeading: '7,017 MMS',
    secondaryHeading: 'Approx 0.0285 per MMS',
    priceLabel: '$200',
    price: 200,
    planId: 5,
    type: 'onetime',
    rateTierId: 4,
  }, {
    primaryHeading: '17,543 MMS',
    secondaryHeading: 'Approx 0.0285 per MMS',
    priceLabel: '$500',
    price: 500,
    planId: 6,
    type: 'onetime',
    rateTierId: 4,
  }, {
    primaryHeading: '35,087 MMS',
    secondaryHeading: 'Approx 0.0285 per MMS',
    priceLabel: '$1000',
    price: 1000,
    planId: 7,
    type: 'onetime',
    rateTierId: 5,
  }, {
    primaryHeading: '52,631 MMS',
    secondaryHeading: 'Approx 0.0285 per MMS',
    priceLabel: '$1500',
    price: 1500,
    planId: 8,
    type: 'onetime',
    rateTierId: 5,
  }, {
    primaryHeading: '70,175 MMS',
    secondaryHeading: 'Approx 0.0285 per MMS',
    priceLabel: '$2000',
    price: 2000,
    planId: 9,
    type: 'onetime',
    rateTierId: 5,
  }, {
    primaryHeading: '105,263 MMS',
    secondaryHeading: 'Approx 0.0285 per MMS',
    priceLabel: '$3000',
    price: 3000,
    planId: 10,
    type: 'onetime',
    rateTierId: 5,
  }, {
    primaryHeading: '192,982 MMS',
    secondaryHeading: 'Approx 0.0285 per MMS',
    priceLabel: '$5500',
    price: 5500,
    planId: 11,
    type: 'onetime',
    rateTierId: 5,
  }],
}
